import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import LoadingSpinner from "./components/loading-spinner/LoadingSpinner";
import Header from "./components/header/Header";
import Message from "./components/message/Message";
// Translations
import i18next from "i18next";
import { useTranslation } from "react-i18next";
// SurveyJS
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import * as SurveyCore from "survey-core";

/* SurveyJS: custom widgets */
// Bootstrapo datepicker
// import { BootstrapDatepickerWidget } from "./components/bootstrap-datepicker/BootstrapDatepicker";
// SurveyCore.CustomWidgetCollection.Instance.add(BootstrapDatepickerWidget);
// Date selector
import { init as initCustomWidget } from "./components/custom-date-widget/CustomDateWidget";
initCustomWidget();

function App() {
  const { t } = useTranslation("common");

  /* URL params: surveyName & participant uid */
  const queryParameters = new URLSearchParams(window.location.search);
  const surveyName = queryParameters.get("surveyName");
  const uid = queryParameters.get("uid");
  document.title = surveyName ?? "Kannact Survey";

  const [control, setControl] = useState<{
    isLoading: boolean;
    isError: boolean;
    message: string | null;
  }>({
    isLoading: false,
    isError: false,
    message: null,
  });

  const [survey, setSurvey] = useState<SurveyCore.Model | null>(null);
  const [brandLogo, setBrandLogo] = useState<string | null>(null);

  // First time load: get survey
  useEffect(() => {
    if (surveyName) {
      getSurvey();
    }
    if (uid) {
      getBrand();
    }
  }, []);

  const changeSurveyLang = () => {
    if (survey) survey.locale = i18next.language || "default";
  };

  // GET
  const getSurvey = () => {
    // Loading
    setControl({ isLoading: true, isError: false, message: "LOADING" });
    axios
      .get(
        `${process.env.REACT_APP_SURVEY_API}/api/public/survey/${surveyName}`
      )
      .then(async (response: AxiosResponse<{ name: string; content: any }>) => {
        // Create survey model
        const tmpSurvey = new SurveyCore.Model(response.data.content);
        tmpSurvey.locale = i18next.language || "default";
        tmpSurvey.showCompletedPage = true;
        tmpSurvey.onComplete.add(onComplete);
        const mergedInfoSurvey = await loadParticipantData(tmpSurvey);
        setSurvey(mergedInfoSurvey);

        // Loaded
        setControl({ isLoading: false, isError: false, message: null });
      })
      .catch((error) => {
        setSurvey(null);
        setControl({ isLoading: false, isError: true, message: "ERROR_GET" });
      });
  };

  // Get participant data and merge it with survey
  const loadParticipantData = async (surveyModel: SurveyCore.Model) => {
    try {
      const response = await axios.get<AxiosResponse<{ name: string }>>(
        `${process.env.REACT_APP_SURVEY_API}/api/public/participant/${uid}`
      );

      const participantData = response.data;

      // Iterate through all questions in the survey
      surveyModel.getAllQuestions().forEach((question) => {
        // Check if there's matching participant data for this question
        if (participantData.hasOwnProperty(question.name)) {
          question.value = participantData[question.name];
        }
      });

      return surveyModel;
    } catch (error) {
      console.error("Failed to load participant data:", error);
      return surveyModel; // Return survey without prefill if participant data fails
    }
  };

  const getBrand = () => {
    axios
      .get(`${process.env.REACT_APP_SURVEY_API}/api/public/brand/${uid}`)
      .then(
        (
          response: AxiosResponse<{
            id: number;
            name: string;
            logo: string;
            icon: string;
            favico: string;
          }>
        ) => {
          // Header: brand logo
          setBrandLogo(response.data.logo);
          // Document: fav.ico
          const link: HTMLLinkElement | null =
            document.querySelector("link[rel~='icon']");
          if (!link) {
            const newLink = document.createElement("link");
            newLink.rel = "icon";
            newLink.href = response.data.favico;
            document.head.appendChild(newLink);
          } else {
            link.href = response.data.favico;
          }
        }
      );
  };

  // Submit
  const onComplete = async (
    sender: SurveyCore.SurveyModel,
    options: SurveyCore.CompleteEvent
  ) => {
    setControl({ isLoading: true, isError: false, message: "SUBMIT_LOADING" });
    await axios
      .post(
        `${process.env.REACT_APP_SURVEY_API}/api/public/survey-results/`,
        {
          participantUid: uid,
          surveyName,
          content: sender.data,
        },
        {
          maxContentLength: 30000000,
          maxBodyLength: 30000000,
        }
      )
      .then((response: AxiosResponse<any>) =>
        options.showSaveSuccess(t("SUBMIT_SUCCESS"))
      )
      .catch((error) => options.showSaveError(t("SUBMIT_ERROR")))
      .finally(() =>
        setControl({ isLoading: false, isError: false, message: null })
      );
  };

  return (
    <div className="App">
      <Header
        languages={survey?.getUsedLocales() ?? []}
        brandLogo={brandLogo}
        onLangChange={() => changeSurveyLang()}
      />

      <main>
        {/* Loading spinner */}
        {control.isLoading && control.message && (
          <LoadingSpinner message={control.message} />
        )}

        {/* Message */}
        {control.isError && control.message && (
          <Message message={control.message} />
        )}

        {/* Survey: content */}
        {!control.isLoading && !control.message && survey && (
          <Survey model={survey} />
        )}
      </main>
    </div>
  );
}

export default App;
